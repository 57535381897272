import type {
  GetStaticPathsContext,
  GetStaticPathsResult,
  GetStaticPropsContext,
} from 'next'
import assignWith from 'lodash/assignWith'
import commerce from '@lib/api/commerce'
import { languageTerritory } from '@commerce/product/use-price'
import { GetProductOperation } from '@commerce/types/product'
import { rootQueryType } from '@shopify/types/component-metafields'
import { ORIGIN_URL, ANKER_COUNTRY_SUFFIX } from '@shopify/const'
import { Pages } from 'templates'
import type { Page } from '@commerce/types/page'
import getProductCouponsBySkus from '@lib/apis/server/get-product-coupons-by-skus'
import {
  normalizeHrefLang,
  normalizeConfCollection,
} from '@framework/utils/normalize'

export async function getStaticPaths({ locales }: GetStaticPathsContext) {
  // const promiseList = locales
  //   ? locales.map((locale) => {
  //       const config = { locale, locales }
  //       return commerce.getAllPages({ config })
  //     })
  //   : []
  // const locales_paths: GetStaticPathsResult = {
  //   paths: pages
  //     .map((page) => String(page.data?.url))
  //     .filter((url) => url !== '/'),
  //   fallback: 'blocking',
  // }

  // await Promise.all(promiseList).then((results) => {
  //   results.forEach((result: any, index) => {
  //     const { pages }: { pages: Page[] } = result
  //     pages.forEach((page: any) => {
  //       if (page?.metafields?.preRender?.mode === 'static') {
  //         locales_paths.paths.push({
  //           params: { pages: page.path.split('/').filter((n: string) => n) },
  //           locale: locales ? locales[index] : '',
  //         })
  //       }
  //     })
  //   })
  // })

  return {
    paths: [],
    fallback: 'blocking',
  }
}

export async function getStaticProps({
  preview,
  previewData,
  params,
  locale = 'us',
  locales = [],
}: GetStaticPropsContext<
  { pages: string[] },
  { __handle: string; __preview_type: rootQueryType }
>) {
  const config = { locale, locales }

  let _previewData = {}
  if (preview && previewData && previewData.__preview_type) {
    const { _preview } = await commerce.getMetafields({
      variables: { slug: previewData.__handle },
      config,
      rootQuery: previewData.__preview_type,
      namespace: 'staging',
      keys: ['_preview'],
    })
    _previewData = {
      ..._preview,
      ...previewData,
    }
  }

  const pagesPromise = commerce.getAllPages({
    config,
    previewData: _previewData,
  })
  const { pages } = await pagesPromise
  const path = params?.pages.join('/')
  const slug = `/${path}`

  const pageItem = pages.find((p: Page) => (p.path ? p.path === slug : false))

  const data =
    pageItem &&
    (await commerce.getPage({
      variables: { id: pageItem.id! },
      config,
      previewData: _previewData,
    }))

  const page = data ? data?.page : null

  const urlPath =
    (locale === 'us' ? '/' : `/${locale}/`) +
    ((params?.pages as string[])?.join('/') || '')

  const siteInfoPromise = commerce.getSiteInfo({
    config,
    previewData: _previewData,
  })

  const { brands, shop, categories, metafields } = await siteInfoPromise

  if (!page) {
    // 404
    return {
      notFound: true,
    }
  }
  // sections metafields
  const sectionMetafields = await commerce.getMetafields({
    previewData: _previewData,
    variables: { slug: data?.page?.handle || '' },
    config,
    rootQuery: 'page',
    namespace: 'section',
    keys: new Array(20).fill(0).map((_, index) => `section_${index + 1}`),
  })

  const order = page?.metafields?.component_order || []
  let componentMetafield: any = []
  if (order.length) {
    componentMetafield = await commerce.getMetafields({
      previewData: _previewData,
      variables: { slug: data?.page?.handle || '' },
      config,
      rootQuery: 'page',
      namespace: 'component',
      keys: order,
    })
  }

  // related products
  const products = page?.metafields?.relatedProducts || []

  const combine_product_handle = ['a17c13z1']
  const combine_product = products?.find((item: any) =>
    combine_product_handle?.includes(item?.handle)
  )

  const combineoptions_products: any = []
  let combineoptionsMetafields: any = null

  if (combine_product) {
    const productPromise = commerce.getProduct({
      variables: { slug: combine_product?.handle },
      config,
    })

    const { product } = await productPromise
    const combineoptions_order = product?.metafields?.combineoptions_order
    const combineoptions_commoninfo =
      product?.metafields?.combineoptions_commoninfo

    // combine_options metafields
    combineoptionsMetafields = await commerce.getMetafields({
      previewData: _previewData,
      variables: { slug: combine_product?.handle },
      config,
      rootQuery: 'product',
      namespace: 'combine_options',
      keys: combineoptions_order,
    })

    combineoptions_order?.forEach((datakey: any) => {
      const combineoptions_data = combineoptionsMetafields?.[datakey]
      if (combineoptions_data) {
        combineoptions_data?.options?.forEach((item: any) => {
          if (item?.related_handle) {
            combineoptions_products.push({
              handle: item?.related_handle?.trim(),
              sku: '',
            })
          }
          if (item?.related_handlesku) {
            const [handle, sku] = item?.related_handlesku.split(',')
            combineoptions_products.push({
              handle: handle?.trim(),
              sku: sku?.trim(),
            })
          }
          if (item?.requisite) {
            const [handle, sku] = item?.requisite.split(',')
            combineoptions_products.push({
              handle: handle?.trim(),
              sku: sku?.trim(),
            })
          }
          if (item?.partSelect?.related_handlesku) {
            const [handle, sku] = item?.partSelect?.related_handlesku.split(',')
            combineoptions_products.push({
              handle: handle?.trim(),
              sku: sku?.trim(),
            })
          }
        })
      }
    })

    if (combineoptions_commoninfo?.sku_to_bundle) {
      Object?.keys(combineoptions_commoninfo?.sku_to_bundle)?.forEach(
        (handlesku) => {
          const [handle, sku] = handlesku.split(',')
          combineoptions_products.push({
            handle: handle?.trim(),
            sku: sku?.trim(),
          })
        }
      )
    }
  }

  const productsPromiseList = products
    ?.concat(combineoptions_products)
    ?.map((item: any) =>
      item.handle
        ? commerce.getProduct({
            variables: { slug: item.handle?.toLowerCase() },
            config,
            previewData: _previewData,
          })
        : () => {}
    )

  const results: GetProductOperation['data'][] = await Promise.all(
    productsPromiseList || []
  )

  const relatedProducts = products
    ?.concat(combineoptions_products)
    .map((product: any, index: any) => ({
      origin: product,
      result: results[index]?.product || {},
    }))

  const all_sku = results
    ?.reduce((pre, cur) => {
      const skus = cur?.product?.variants?.map(
        (variant) => variant?.sku || ''
      ) as never[]
      return pre.concat(skus ?? []) // Handle undefined case
    }, [])
    ?.filter(
      (item, index, self) =>
        !!item && index === self?.findIndex((sku) => sku === item)
    )

  let relatedCoupons = {}

  try {
    relatedCoupons = await getProductCouponsBySkus(locale, [all_sku])
  } catch (error) {
    console.log('...pages_error', error)
  }

  const mergeMetafields = assignWith(
    metafields,
    page?.metafields,
    (obj, page) => (!!page ? page : obj)
  )

  const hrefLangResult = normalizeHrefLang({
    hrefLangInfos: page?.metafields?.seoSetting?.hrefLangInfos || {},
    locale,
    locales,
    slug,
  })
  const languageAlternates: any[] = []

  // console.log('hrefLangResult', hrefLangResult)

  if (!hrefLangResult) {
    const pagesHrefLang = locales?.map((item: any) =>
      commerce.getAllPages({
        config: {
          locale: item,
          locales,
        },
      })
    )

    const pagesLangResults = await Promise.all(pagesHrefLang || [])
    locales?.forEach((lang, g) => {
      const pageItem = pagesLangResults[g]?.pages.find((p: Page) =>
        p.path ? p.path === slug : false
      )
      if (pageItem) {
        languageAlternates.push({
          hrefLang: languageTerritory(lang || 'us'),
          href: `${ORIGIN_URL}${lang === 'us' ? '' : '/' + lang}${slug}`,
        })
      }
    })

    if (languageAlternates.length > 0) {
      languageAlternates.push({
        hrefLang: 'x-default',
        href: languageAlternates[0].href,
      })
    }
  }

  return {
    props: {
      type: 'page',
      preview: !!preview,
      previewData: _previewData,
      brands,
      slug,
      page,
      shop,
      navCategories: normalizeConfCollection(metafields, categories),
      pageType: metafields?.pageType?.pageType || 'charger',
      handle: data?.page?.handle || '',
      seo: {
        ...(page?.seo || {}),
        title: page?.seo?.title || page?.name || '',
        canonical:
          metafields?.seoSetting?.canonical ||
          `${ORIGIN_URL}${locale === 'us' ? '' : `/${locale}`}${slug}`,
        languageAlternates: hrefLangResult || languageAlternates,
        openGraph: {
          ...(page?.seo || {}),
          site_name: 'Anker',
          type: 'website',
        },
      },
      metafields: {
        ...mergeMetafields,
        sections: sectionMetafields || null,
        components: componentMetafield || null,
        combineoptions: combineoptionsMetafields || null,
        relatedCoupons,
      },
      relatedProducts: relatedProducts || [],
    },
    revalidate: 56 * 3,
  }
}

export default Pages
